* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-align: justify;
  font-family: Arial,  sans-serif
}

td, th {
  border: 1px solid #ddd;
  padding: 8px;
}

.navBarItem {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  float: left;
}


.contactEmail {
  color: #2596be
}

.contactName {
  /* color: #001345; */
  color: #fff;
font-weight: bold;
  text-decoration: none;
  text-align: justify;
}
.CnlVH {
  border-radius: 30px
}

.knowledgeVideos {
  max-width: inherit;
  }

  .jauRVU{
    right: 50%;
    position: relative;
    height: 60vh;
    width: 60vw;
    max-width: 200%;
    padding-bottom: 3vh;
    -o-object-fit: cover;
    object-fit: contain;
    background: #001017;
  }
  
  .fwUsAQ {
    padding-top: 5vh;
    padding-bottom: 5vh;
    height: auto !important;
  }

.sc-uhnfH.kWqjdO {
  margin-top: 20vh !important
}



    .sc-hAQmFe.jSuFNy{
width: 150%

    }
.sc-gJqSRm.iPlyEv {

  margin-bottom: 10vh;;
}

 .generateButton {
  display: flex;
  justify-content: flex-start;
  background:#f3f3f3;     
  padding-bottom: 20px; 
  padding-top:10px;
  justify-content: center;
   width: 20pc; 
   left: 45% !important; 
   position: relative; 
   align-items: center;
 }



 .languageSelect {

  height: 80px;
  width: 150px;
  float: left;
  position: absolute;
  left: 89vw;
}
@media only screen and (max-width: 900px) {
.languageSelect {

  height: 8vh;
  width: 15vw;
  float: left;
  position: relative;
  left: 50vw;
  top:-23vh;
}
}
select.selectLng {
  border-color: white;
  background: #2596be;
  color:#fff;
  font: inherit;
  border-radius: 5vh;
  width: fit-content;
  margin: 2vh;
  padding: 1vh;

}
@media (min-width: 0px) and (max-width: 700px) {
select.selectLng {
  border-color: white;
  background: #2596be;
  color:#fff;
  font: inherit;
  border-radius: 5vh;
  width: fit-content;
  margin: 2vh;
  padding: 1vh;
}
}


.sc-gKPRtg {
  color: black
}

.manualButton {
background: #003145;
white-space: nowrap;
border-radius: 50vh !important;
padding: 1vh 30px  !important/*'12px 30px'*/;
margin-left: 0vw !important;
margin-right: 14vw !important;
color: #fff /*'#fff'*/;
font-size: 18px !important /*16px*/;
outline: none !important;
border: none !important;
cursor: pointer !important;
display: flex !important;
justify-content: center !important;
align-items: center !important;
transition: all 0.2s ease-in-out !important;
text-decoration: none !important;
width: fit-content;
}

.manualButton:hover {
    transition: all 0.2s ease-in-out;
    background: #1796BC/*#1796BC*/; 
    color:#fff;
}
.manualButtonContact {
  background: #1796BC;
  white-space: nowrap;
  border-radius: 50vh !important;
  padding: 1vh 30px  !important/*'12px 30px'*/;
  margin-left: 0vw !important;
  margin-right: 14vw !important;
  color: #003145 /*'#fff'*/;
  font-size: 18px !important /*16px*/;
  outline: none !important;
  border: none !important;
  cursor: pointer !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transition: all 0.2s ease-in-out !important;
  text-decoration: none !important;
  width: fit-content;
  }
  
  .manualButtonContact:hover {
      transition: all 0.2s ease-in-out;
      background: #003145/*#1796BC*/; 
      color:#fff;
  }

/* #rcc-decline-button {

  background-color: rgb(156, 0, 0) !important;
  border-radius: 10px !important;
}

#rcc-confirm-button {

  background-color: rgb(3, 161, 45) !important;
  color: white !important;
  border-radius: 10px !important;

}

#onetrust-policy-text a{

  color: white;
}

.CookieConsent {
  padding-top: 150px;
  padding-bottom: 150px;
  background: #0f2934e8 !important;
bottom: 30% !important;
} */